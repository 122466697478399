import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            location
          }
          footerLinks {
            name
            location
          }
        }
      }
    }
  `);

  return (
    <>
      <Header menuLinks={data.site.siteMetadata.menuLinks} />
      <main>{children}</main>
      <Footer footerLinks={data.site.siteMetadata.footerLinks} />
    </>
  );
};

export default Layout;
