import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(3)
  },
  icon: {
    fontSize: "4rem",
    color: theme.palette.primary.main
  },
  figure: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

const InlineNumberPoint = ({ Icon, figure, title, description }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid container alignItems="center">
        <Grid item xs={3} sm={4} lg={3}>
          {Icon && <Icon className={classes.icon} />}
        </Grid>
        <Grid item xs={9} sm={8} lg={9}>
          <Typography className={classes.figure} variant="h3" component="div">
            {figure}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3} sm={4} lg={3} />
        <Grid item xs={9} sm={8} lg={9}>
          <Typography variant="h6">
            <Typography
              variant="inherit"
              className={classes.title}
              component="span"
            >
              {title}
            </Typography>{" "}
            <Typography variant="inherit" component="span">
              {description}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InlineNumberPoint;
