import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Link from "./Link";

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0
    }
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "4.5rem",
    marginBottom: theme.spacing(1)
  },
  description: {
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(2)
  }
}));

const StackedPoint = ({
  Icon,
  title,
  description,
  link,
  linkText = "Learn more"
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Icon ? <Icon className={classes.icon} /> : null}
      <Typography className={classes.title} variant="h5" component="div">
        {title}
      </Typography>
      <Typography className={classes.description} variant="body1">
        {description}
      </Typography>
      {link ? <Link href={link}>{linkText}</Link> : null}
    </div>
  );
};

export default StackedPoint;
