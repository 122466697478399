import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import logo from "../images/logo.svg";
import Link from "./Link";

const useStyles = makeStyles(theme => ({
  footerContainer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: theme.spacing(5)
  },
  logo: {
    marginBottom: theme.spacing(3)
  },
  footerTitle: {
    fontWeight: theme.typography.fontWeightBold
  },
  footerLink: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  footerLinkActive: {
    color: theme.palette.primary.main
  },
  subFooterContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2)
  }
}));

const Footer = ({ footerLinks }) => {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <footer className={classes.footerContainer}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5}>
            <img
              width={220}
              className={classes.logo}
              src={logo}
              alt="Commercial Finance Group"
            />
            <Typography paragraph>
              CFG (Commercial Finance Group) Limited is fully authorised with
              the FCA and can act as a 'Principle Firm' under the Ref:{" "}
              <Link href="https://register.fca.org.uk/ShPo_FirmDetailsPage?id=001b000000j18KQAAY">
                665775
              </Link>
            </Typography>
            <Typography variant="body2">
              CFG (Commercial Finance Group) Limited operates as a commercial
              finance broker and is not a Lender.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography
              className={classes.footerTitle}
              variant="h6"
              component="div"
            >
              Links
            </Typography>
            <List dense>
              {footerLinks.map(item => (
                <ListItem key={item.location} disableGutters>
                  <Link
                    className={classes.footerLink}
                    color="inherit"
                    underline="none"
                    href={item.location}
                    activeClassName={classes.footerLinkActive}
                  >
                    {item.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Typography
              className={classes.footerTitle}
              variant="h6"
              component="div"
            >
              Get In Touch
            </Typography>
            <List dense>
              <ListItem disableGutters>
                <ListItemIcon>
                  <SmartphoneIcon />
                </ListItemIcon>
                <ListItemText primary="0116 298 7344" />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <AlternateEmailIcon />
                </ListItemIcon>
                <ListItemText primary="info@commercialfinancegroup.co.uk" />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary="CFG, 169 London Road, Leicester, LE2 1EG" />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary="Monday - Friday, 9am - 5pm" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.subFooterContainer}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                &copy; Copyright 2009-{year} CFG (Commercial Finance Group)
                Limited
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
