import { Container, Grid, Typography } from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import { makeStyles } from "@material-ui/styles";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import Button from "../components/Button";
import CheckList from "../components/CheckList";
import InlineNumberPoint from "../components/InlineNumberPoint";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";
import StackedPoint from "../components/StackedPoint";

const useStyles = makeStyles(theme => ({
  heroContainer: {
    backgroundColor: theme.palette.common.dark,
    color: theme.palette.common.white
  },
  heroTitle: {
    marginBottom: theme.spacing(2)
  },
  heroButton: {
    marginTop: theme.spacing(3)
  },
  servicesSubtitle: {
    marginBottom: theme.spacing(4)
  },
  otherServicesContainer: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const CashFlowPage = () => {
  const classes = useStyles();
  const title = "Cash Flow";
  const description =
    "If you are looking for assistance in financing piece of equipment, vehicles, or even software, we have a number of lenders that are willing to help, and a team of experienced brokers to guide you through the process.";

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.heroContainer}>
        <Container>
          <Typography className={classes.heroTitle} variant="h1">
            {title}
          </Typography>
          <Typography variant="subtitle1" paragraph>
            {description}
          </Typography>
          <CheckList
            points={[
              "Get cash flow and taxation benefits",
              "Get fast results",
              "No fees"
            ]}
          />
          <Button
            className={classes.heroButton}
            variant="contained"
            color="primary"
            giant
            component={GatsbyLink}
            to="/business-loan"
          >
            Get Quote
          </Button>
        </Container>
      </Section>
      <Section>
        <Container>
          <Typography
            className={classes.servicesSubtitle}
            variant="h6"
            component="h1"
          >
            Services
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <StackedPoint
                title="Business Loan"
                description="Trading 2+ years? You could be eligible to draw up to £250k in under 24 hours. Find out how much you could borrow today!"
                link="/business-loan/"
                linkText="Get Quote"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StackedPoint
                title="Asset Finance"
                description="Our experienced team will find lenders who can offer you flexible funding options and low rates."
                link="/asset-finance/"
                linkText="Get Quote"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StackedPoint
                title="Invoice Finance"
                description="Improve your cashflow by utilising a low rate, flexible invoice finance facility. We will help you find the best solution for your business."
                link="/invoice-finance/"
                linkText="Get Quote"
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section className={classes.otherServicesContainer}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <InlineNumberPoint
                Icon={GroupOutlinedIcon}
                figure="50+"
                title="Expert"
                description="advisers ready to help find funding."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InlineNumberPoint
                Icon={LocalOfferOutlinedIcon}
                figure="£35b+"
                title="In projects"
                description="handled by our team."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InlineNumberPoint
                Icon={EventAvailableIcon}
                figure="10"
                title="Years"
                description="established commercial finance specialist."
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default CashFlowPage;
