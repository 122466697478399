import {
  AppBar,
  Container,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  useScrollTrigger
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import { Link as GatsbyLink } from "gatsby";
import React, { useState } from "react";
import logo from "../images/logo.svg";
import Link from "./Link";

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const useStyles = makeStyles(theme => ({
  headerAppBar: {
    backgroundColor: "white",
    boxShadow: "none"
  },
  headerToolBar: {
    minHeight: theme.spacing(10)
  },
  headerNavigation: {
    display: "flex",
    justifyContent: "flex-end"
  },
  headerNavigationList: {
    display: "flex",
    padding: 0,
    margin: 0,
    listStyle: "none"
  },
  headerNavigationLink: {
    padding: theme.spacing(1, 1),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  headerNavigationLinkActive: {
    position: "relative",
    color: theme.palette.primary.main,
    "&:after": {
      position: "absolute",
      content: '" "',
      width: theme.spacing(2.5),
      height: 2,
      left: `calc(50% - ${theme.spacing(1.25)}px)`,
      bottom: 0,
      backgroundColor: theme.palette.primary.main
    }
  },
  headerNavigationDrawerButton: {
    display: "flex",
    marginLeft: "auto"
  },
  headerNavigationDrawerLink: {
    minWidth: 200
  },
  headerNavigationDrawerLinkActive: {
    color: theme.palette.primary.main
  }
}));

const Header = ({ menuLinks }) => {
  const [menuOpen, setMenu] = useState(false);
  const classes = useStyles();

  const handleMenuClose = () => {
    setMenu(false);
  };

  const handleMenuOpen = () => {
    setMenu(true);
  };

  return (
    <>
      <HideOnScroll>
        <AppBar color="inherit" className={classes.headerAppBar}>
          <Toolbar className={classes.headerToolBar}>
            <Container>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Link href="/">
                    <Hidden implementation="css" mdUp>
                      <img
                        width={175}
                        src={logo}
                        alt="Commercial Finance Group"
                      />
                    </Hidden>
                    <Hidden implementation="css" mdDown>
                      <img
                        width={200}
                        src={logo}
                        alt="Commercial Finance Group"
                      />
                    </Hidden>
                  </Link>
                </Grid>
                <Grid item xs component={Hidden} implementation="css" mdDown>
                  <nav
                    className={classes.headerNavigation}
                    aria-label="Primary Navigation"
                  >
                    <ul className={classes.headerNavigationList}>
                      {menuLinks.map(item => (
                        <li key={item.location}>
                          <Link
                            color="inherit"
                            className={classes.headerNavigationLink}
                            underline="none"
                            href={item.location}
                            activeClassName={classes.headerNavigationLinkActive}
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Grid>
                <Grid item xs component={Hidden} implementation="css" mdUp>
                  <IconButton
                    className={classes.headerNavigationDrawerButton}
                    onClick={handleMenuOpen}
                    aria-label="Open primary navigation"
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                  <Drawer
                    open={menuOpen}
                    anchor="right"
                    onClose={handleMenuClose}
                  >
                    <List component="div">
                      {menuLinks.map(item => (
                        <ListItem
                          className={classes.headerNavigationDrawerLink}
                          button
                          key={item.location}
                          component={GatsbyLink}
                          to={item.location}
                          activeClassName={
                            classes.headerNavigationDrawerLinkActive
                          }
                        >
                          <ListItemText>{item.name}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar className={classes.headerToolBar} />
    </>
  );
};

export default Header;
