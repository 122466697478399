import { Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles(theme => ({
  base: {
    textTransform: "capitalize",
    boxShadow: "none"
  },
  rounded: {
    borderRadius: theme.spacing(5)
  },
  giant: {
    padding: theme.spacing(1.65, 5),
    fontSize: "1rem"
  }
}));

const Button = ({
  children,
  className,
  giant = false,
  rounded = true,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiButton
      className={classnames({
        [className]: className,
        [classes.base]: true,
        [classes.giant]: giant,
        [classes.rounded]: rounded
      })}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
