import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  listItemText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h6.fontSize
  }
}));

const CheckList = ({ points }) => {
  const classes = useStyles();

  return (
    <List dense>
      {points.map(point => (
        <ListItem key={point} disableGutters>
          <ListItemIcon>
            <CheckIcon fontSize="large" color="primary" />
          </ListItemIcon>
          <ListItemText
            className={classes.listItemText}
            primary={point}
            disableTypography
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CheckList;
