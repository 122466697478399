import React from "react";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";

const useStyles = makeStyles(theme => ({
  section: {
    padding: props => theme.spacing(...props.gutters)
  }
}));

const Section = ({ className, children, gutters = [10, 0], ...rest }) => {
  const classes = useStyles({ gutters });

  return (
    <section className={classnames(className, classes.section)} {...rest}>
      {children}
    </section>
  );
};

export default Section;
