import React from "react";
import { Link as MuiLink } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";

const Link = ({ href = "", children, ...rest }) => {
  const isInternal = href.startsWith("/");

  if (isInternal) {
    return (
      <MuiLink component={GatsbyLink} to={href} {...rest}>
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink
      href={href}
      target="_blank"
      rel="external noopener noreferrer"
      {...rest}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
